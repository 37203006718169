/************************* MEDIA QUERIES *********************************/

@media (min-width: 15rem) {
  .techText {
    min-width: 100%;
  }
}

@media (min-width: 40rem) {
  .techText {
    max-width: 85%;
  }
}

@media (min-width: 60rem) {
  .techText {
    min-width: 75%;
  }
}

.techText {
  max-width: 50%;
}

#experiencesID {
  margin-top: 3rem;
  padding-top: 3rem;
}

#experiencesID .fntAwesome {
  color: var(--secondaryColor);
}

/************************* EXP Menu *********************************/

#expMenu {
  margin: auto;
  padding: 0.25rem;
  border-radius: 25%;
  width: fit-content;
  max-width: 100vw;
  /* border: 0.15rem solid black; */
}

#expMenu button {
  margin: auto !important;
  margin-bottom: 1rem !important;
  margin-left: 0rem !important;
  margin-right: 1.25rem !important;
  display: inline-block !important;
  width: 100% !important;
}

.techCategory:hover {
  background-color: var(--btnHoverColor) !important;
}

.techCategory:focus {
  background: linear-gradient(
    to left,
    var(--btnHoverColor) 50%,
    var(--secondaryColor-darkest)
  );
  background-size: 200%;
  background-position: right bottom;
  transition: all 2s ease-out;
  border: none !important;
}

.expTable {
  display: inline-block;
  margin: auto;
  margin-top: 0.5rem;
  position: relative;
  background-color: var(--primary-white);
  padding: 1rem;
  border: 0.1rem solid black;
  border-radius: 18px;
  box-shadow: var(--btnDesinShadow);
}

.techCategory h3 {
  color: var(--primary-white);
  white-space: nowrap;
}
/* @media (min-width: 768px) {
  #expMenu {
    width: 75%;
  }
} */

#expCategoryMenu {
  background-color: var(--bg-sub-dark) !important;
  box-shadow: var(--btnDesinShadow);
  border: 0.1rem solid black;
  padding-top: 1.25rem;
  padding-left: 1rem;
  /* 
  padding-right: 0.5rem; */
  border-radius: 0.65rem;
  overflow-x: auto;
  width: 100%;
}

#backGroundExp {
}
/* #expCategoryMenu {
  /* overflow: auto; */
/* border-radius: 18px;
  padding: 0.5rem;

  width: fit-content; */
/* display: inline-flex; */
/* background-color: var(--bg-sub-dark); */

.techCategory {
  background-color: var(--secondaryColor);

  border-radius: 18px;
  padding: 1rem;
  margin-bottom: 2vh !important;
  margin: auto;
  margin-left: 1rem;
  margin-right: 1rem;
  box-shadow: var(--btnShadow);
  border: 0.1rem black solid;
}
/* Handle */
::-webkit-scrollbar {
  width: 1rem;
  height: 0.6rem !important;
  box-shadow: inset 0 0 0.5rem var(--dark-slate);
  background-color: var(--darkest-navy);
}

/* Track */
::-webkit-scrollbar-track:horizontal {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb:horizontal {
  border-radius: 16px;
  box-shadow: none;
  height: 3rem;
  width: 12rem;
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: var(--secondaryColor-darkest);
}

/************************* EXP Information *********************************/
#_container {
  opacity: 1;
}

#_periodSystem {
  margin: auto;
}

.headerTech {
  color: var(--secondaryColor-darkest) !important;
  position: relative;
  width: fit-content;
  text-align: left;
  word-break: normal;
  margin-bottom: 1rem;
}

.periodColContainer {
  display: block;
  max-width: 8.75rem;
  width: max-content;
  float: left;
}

#confidentialParagraph {
  font-style: italic;
  display: none;
  text-align: left;
  margin: 0.5rem;
}
.header3Tech {
  color: var(--primaryText);
  display: inline-block;
  height: fit-content;
  max-width: 11.25rem;
  width: max-content;
  align-content: center;
  position: relative;
  text-align: left;
  word-break: keep-all;
  white-space: nowrap !important;
  border-width: 1rem;
  margin: 0.25rem;
  margin-left: 0;
  padding-left: 0 !important;
  float: left;
}

.techRow {
  border-radius: 18px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: var(--secondaryColor-dark);
  border: 0.1rem solid black;
  height: fit-content;
  opacity: 0;
}

.techSkill {
  color: var(--primary-white);
  margin: auto;
  padding: 1%;
  height: fit-content;
}

.techUnderLine {
  background-color: var(--secondaryColor-darkest);
  margin-bottom: 1rem;
  margin: auto;
  width: 100%;
  height: 0.15rem;
  margin-top: -1rem;
}

#techRowOneID {
  width: 80%;
}

#techRowTwoID {
  width: 30%;
  height: fit-content;
}

#techRowThreeID {
  width: 20%;
  height: fit-content;
}

.techText {
  text-align: left;
  white-space: normal !important;
  opacity: 0;
  padding: 0.25rem;
  /* display: inline-block; */
  float: left;
  margin-top: 1rem;
  width: fit-content;
}

#_descOne,
#_descTwo,
#_descThree,
#_descFour {
  padding-left: 0;
}

.majorExphighLight {
  color: var(--secondaryColor-darkest) !important;
}

.textText p span {
  word-break: keep-all;
}

ul {
  list-style: none;
}

.li_icon {
  /* font-weight: bold;
  display: inline-block;
  width: 0.2rem;
  margin-left: -0.8rem;
  margin-right: 0.6rem;
  color: var(--secondaryColor-darkest);
  border: 0.1rem solid black;
  position: relative;
  opacity: 0; */
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 0.325rem solid transparent; /* adjust size as desired */
  border-bottom: 0.325rem solid transparent; /* adjust size as desired */
  border-left: 0.425rem solid var(--secondaryColor-dark); /* adjust color and size as desired */
  margin-top: 0.25rem;
  margin-left: 1rem;
  /* margin-right: 0.6rem; */
}

.techSkillContainer {
  display: none;
  opacity: 0;
  width: 100%;
}

/************************* Tech skills *********************************/

#fintech_css {
  width: 40%;
}

#fintech_csharp {
  width: 30%;
  padding: 0.25rem;
}

#municipality_3_csharp {
  width: 20%;
}

#municipality_3_ps1 {
  width: 40%;
}

#fintech_angular {
  width: 90%;
}

#municipality_3_sql {
  width: 90%;
}

#municipality_2_sql {
  width: 100%;
}

#municipality_2_javascript {
  width: 100%;
}

#municipality_2_htmlcss {
  width: 100%;
}

#municipality_2_csharp {
  width: 100%;
}
