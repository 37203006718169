/* Extra Small (XS) devices */
@media (max-width: 34rem) {
  /* CSS code for XS devices */
  #tableFamilyID {
    transform: scale(0.3) !important;
  }
}

/* Small (SM) devices */
@media (min-width: 34rem) and (max-width: 48rem) {
  /* CSS code for SM devices */
  #tableFamilyID {
    transform: scale(0.25) !important;
  }
}

/* Medium (MD) devices */
@media (min-width: 48rem) and (max-width: 62rem) {
  /* CSS code for MD devices */
  #tableFamilyID {
    transform: scale(0.27) !important;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}

/* Large (LG) devices */
@media (min-width: 62rem) and (max-width: 75rem) {
  /* CSS code for LG devices */
  #tableFamilyID {
    transform: scale(0.275) !important;
    margin-bottom: 0.55rem;
    margin-top: 0.5rem;
  }
}

/* Extra Large (XL) devices */
@media (min-width: 75rem) {
  /* CSS code for XL devices */
  #tableFamilyID {
    transform: scale(0.27) !important;
    margin-bottom: 0.5rem;
    margin-top: 0.75rem;
  }
}

#tableFamilyID {
  z-index: 2;
  border-spacing: 5% 0%;
  border-collapse: collapse;
  height: fit-content;
  max-width: 50rem;
}

#tableFamilyID td {
  border-spacing: 0;
}

.wrapper {
  /* display: table-cell; */
  vertical-align: middle;
  position: relative;
  padding: 0rem;
  /* border: 1rem solid var(--primary-white); */
  border-radius: 50%;
  padding: 1rem;
  /* width: 100%; */
  height: fit-content;
}

.background-circle {
  width: 25rem;
  height: 25rem;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto;

  background: #95b3bf;
  position: relative;
}

.edip-body {
  width: 17.8125rem;
  font-family: "Open Sans", sans-serif;
  height: 37.5rem;
  margin: 0 auto;
  background: #423c0a !important;
  border-radius: 6.25rem;
  position: relative;
  top: 12.5rem;
  box-shadow: 0.15rem 2rem 2.5rem -1rem #cecbb7,
    inset 0 1rem 1.5rem 2.25rem #373206;
}

.erika-body {
  width: 17.8125rem;
  font-family: "Open Sans", sans-serif;
  height: 37.5rem;
  margin: 0 auto;
  background: #197250;
  border-radius: 6.25rem;

  position: relative;
  top: 12.5rem;
  box-shadow: 0 1.25rem 0rem #83ffb3, inset 1rem 1rem 1.5rem 0.5rem #27956b;
}

.laleh-body {
  width: 21rem;
  font-family: "Open Sans", sans-serif;
  height: 15.5rem;
  margin: 0 auto;
  background: #cc69e4;
  border-radius: 6.25rem;
  position: relative;
  top: 7.5rem;

  box-shadow: 0 -0.5rem 0.5rem 0.1rem #e799fb,
    inset 0.25rem 0.25rem 2rem 0.5rem #7d428c;
}

.noah-body {
  width: 12.8125rem;
  font-family: "Open Sans", sans-serif;
  height: 8.5rem;
  margin: 0 auto;
  background: #ff6e4a;
  border-radius: 6.25rem;
  position: relative;
  top: 10.5rem;
  box-shadow: 0 0 0 0 #fda791, inset -0.15rem 0.25rem 2.25rem 2.15rem #db411b;
}

.head {
  width: 12.25rem;
  height: 16.25rem;
  border-radius: 3.125rem;
  background: #ffdaa7;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13.125rem;
  margin-left: -6.125rem;
  animation: music-move 1.5s 3.3s infinite alternate ease-in-out;
  -webkit-animation: music-move 1.5s 3.3s infinite alternate ease-in-out;
}

.erika-head {
  border-radius: 6.25rem;
  width: 11.875rem;
  height: 13.75rem;
}

.noah-head {
  border-radius: 6.25rem;
  width: 11.875rem;
  height: 12rem;
}

.laleh-hair-bg {
  width: 10rem;
  height: 1.5rem;
  background: #6d382764;
  border-radius: 6.5rem 6.5rem 1% 1%;
  margin-left: 2.5rem;
  position: relative;
}

.laleh-hair {
  background-color: transparent;
  position: absolute;

  left: 44%;
  width: 2rem;
  height: 3rem;
  border-radius: 50%;
  border-left: 0.725rem solid #79412e;
  border-bottom: 0.7125rem solid #79412e;
  z-index: 200;
}

.laleh-head {
  border-radius: 6.25rem;
  width: 15.875rem;
  height: 15rem;
  background-color: #ffdaa7;
  margin-left: -7.5rem;
  border-bottom: 0.1rem black solid;
  z-index: 100;
  box-shadow: 0.5rem 0.25rem 0.25rem -0.25rem #f4c37e,
    inset 0.25rem 0.25rem 2rem 0.25rem #9e7843;
}

.cheek {
  background-color: #ffb7bf;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
}

.cheek-left {
  left: 5%;
}

.cheek-right {
  right: 5%;
}
.laleh-kawai {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin: auto;
  margin-top: 0.15rem;
  background-color: white;
}

.hair-main {
  width: 13.75rem;
  height: 8.5625rem;
  background: linear-gradient(#141313 60%, rgb(83, 77, 77) 40%) !important;
  border-radius: 3.375rem 3.375rem 0rem 0rem;
  margin-left: -0.75rem;
  margin-top: -0.625rem;
  position: relative;
  z-index: 2;
  box-shadow: 0.25rem 0.25rem 0.5rem -0.5rem #adabab,
    inset 0.25rem 0.5rem 1rem 0.5rem #3c3939;
}

.hair-ball {
  height: 4.0625rem;
  width: 4.0625rem;
  background: #eae567;
  margin-left: 35%;
  margin-top: -5rem;
  top: 50;

  border-bottom-left-radius: 40%;
  border-bottom-right-radius: 40%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  box-shadow: 0 0rem 0.25rem 0 var(--primary-white),
    inset 0 -0.5rem 1rem 0.25rem rgb(198, 193, 68);
}

.erika-hair {
  background: #eae567;

  width: 15.625rem;
  height: 8.5625rem;
  border-radius: 4.0625rem 4.0625rem 3.75rem 3.75rem;
  margin-left: -1.875rem;
  margin-top: 0.5rem;
  position: absolute;
  z-index: 2;
  box-shadow: 0 0rem 0.25rem 0 var(--primary-white),
    inset 0 -0.5rem 1rem 0 #959241;
}

.noah-hair-top {
  background: #edd88a;

  width: 11.5rem;
  height: 8rem !important;
  border-radius: 35%;
  margin-left: -1.5rem;
  margin-top: -1.5rem;
  position: relative;
  z-index: 2;
  box-shadow: 0 0rem 0.25rem 0 var(--primary-white),
    inset 1.25rem 0.5rem 1rem 0 #959241;
  border: 18px;
}

.noah-hair-side-left {
  background: #edd88a;

  width: 2.95rem !important;
  height: 13rem !important;
  border-radius: 0rem 1.375rem 2.375rem 0rem;
  margin-left: -1.25rem;
  margin-top: -6.25rem;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 0 0 var(--primary-white),
    inset 0.5rem -0.45rem 0.5rem 0.1rem #959241;
  border: 18px;
}

#firstRightCurly {
  box-shadow: 0 0rem 0rem 0 var(--primary-white),
    inset -0.75rem 0.45rem 0.9rem 0rem #959241;
  width: 5.5rem !important;
}

#firstLeftCurly {
  box-shadow: 0 0rem 0rem 0 var(--primary-white),
    inset 1rem -0.15rem 0.75rem 0 #959241;
  border-top: transparent;
  border: transparent !important;
  width: 4rem;
}

.noah-hair-side-right {
  background: #edd88a;

  width: 3.5rem;
  height: 15.5rem !important;
  border-radius: 0rem 5rem 0rem 5rem;
  margin-left: 10.25rem;
  margin-top: -0.5rem !important;
  position: relative;
  box-shadow: 0 0 0 0 var(--primary-white),
    inset -0.5rem -0.45rem 0.5rem 0.1rem #959241;
}

.curly-ball-right {
  background: #edd88a;
  margin-left: 0.25rem;
  width: 5rem;
  height: 2.5rem;
  border-radius: 0rem 2rem 0rem 2rem;
  box-shadow: 0 0rem 0 0 var(--primary-white),
    inset -1rem -0.15rem 0.75rem 0 #959241;
}

.curly-ball-left {
  background: #edd88a;

  width: 4.6rem;
  height: 2.5rem;
  border-radius: 2rem 0rem 2rem 0rem;
  margin-left: -1.25rem;
  box-shadow: 0 0rem 0 0 var(--primary-white),
    inset 1rem -0.15rem 0.75rem 0 #959241;
}
.beard {
  width: 13.7rem;
  height: 8.6rem;
  background: #111111 !important;
  border-radius: 0.3125rem 0rem 3.375rem 3.375rem;
  margin-left: -6%;
  margin-top: -0.15rem;
  position: relative;
  z-index: 2;
  box-shadow: 0 0 0.5rem -0.5rem #adabab,
    inset 0.25rem 0.5rem 2rem 0.5rem #3c3939;
}

.face {
  width: 11.25rem;
  height: 8.5625rem;
  border-radius: 3rem 3rem 0 0;
  background: #ffdaa7;
  position: absolute;
  top: 2.5rem;
  left: 0.5rem;
  z-index: 3;
  box-shadow: 0 0 0.25rem 0 #f4c37e, inset 0 0 1.5rem 0.25rem #9c7742;
}

.erika-face {
  width: 11.25rem;
  border-radius: 4rem 4rem 4rem 4rem;
  left: 0.3125rem;
  margin-left: 2%;
}

.freckles-left {
  height: fit-content;
  width: fit-content;
  display: inline-flex;
  justify-content: center;
}

.freckles-right {
  height: fit-content;
  width: fit-content;
  display: inline-flex;
  justify-content: center;
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}
.freckle {
  width: 0.3rem;
  height: 0.3rem;
  background-color: #b3894f;
  border-radius: 50%;
  display: inline-block;
}

.freckle_1 {
  margin-top: 6.4rem;
  margin-left: -7rem;
}

.freckle_2 {
  margin-top: 6rem;
  margin-left: 0.6rem;
}

.freckle_3 {
  margin-top: 6.75rem;
  margin-left: -0.25rem;
}

.erika-head {
  box-shadow: 0.1rem 0 0.1rem #f4c37e, inset 0 -0.5rem 1rem 0 #9e7843;
}

.face.erika-face {
  box-shadow: 0 0 0 #f4c37e, inset 0rem 0.5rem 1rem -0.75rem #9e7843;
}

.noah-face {
  width: 10.8rem;
  height: 9.75rem;
  border-radius: 4rem 4rem 4rem 4rem;
  left: 0.3125rem;
  box-shadow: 0 0.1rem 0.25rem 0 #ffdaa7, inset 0 -0.2rem 1rem 0 #9e7843;
}
.laleh-face {
  width: 11.25rem;
  height: 12rem;
  border-radius: 4rem 4rem 4rem 4rem;
  background-color: #ffdaa7;
  left: 2rem;
  box-shadow: 0 0rem 0rem 0 #f4c37e, inset 0 0rem 0rem 0 #9e7843;
}

.nose {
  background: #e7ab57;
  width: 1.25rem;
  height: 2.8125rem;
  border-radius: 2.125rem;
  position: absolute;
  left: 50%;
  top: 5rem;
  margin-left: -0.625rem;

  z-index: 4;
  box-shadow: 0 0.1rem 0.5rem 0.1rem #e7ab57,
    inset 0rem -0.45rem 0.5rem 0.25rem #5a4322;
}

.erika-nose {
  background: #ff8597 !important;
  height: 0.625rem !important;
  border-radius: 0rem 0rem 2.125rem 2.125rem !important;
  margin-top: 1.25rem !important;
  box-shadow: 0 0.1rem 0.1rem 0 #c35263,
    inset 0rem -0.1rem 0.25rem 0 var(--primary-white);
}

.noah-nose {
  background: #ff8597 !important;
  height: 0.75rem !important;
  border-radius: 0rem 0rem 2.125rem 2.125rem !important;
  margin-top: 1.25rem !important;
  box-shadow: 0 0.1rem 0.1rem 0 #c13f53,
    inset 0rem -0.1rem 0.25rem 0 var(--primary-white);
}

.laleh-nose {
  background: #fc3956 !important;
  height: 0.9rem !important;
  border-radius: 0rem 0rem 2.125rem 2.125rem !important;
  margin-top: 0.75rem !important;
  box-shadow: 0 0.1rem 0.1rem 0 #fa2645,
    inset 0rem -0.1rem 0.25rem 0 var(--primary-white);
}

.mouth {
  width: 4.125rem;
  height: 2.0625rem;
  border-radius: 0 0 2.0625rem 2.0625rem;
  background: white;
  position: absolute;
  top: 9.375rem;
  left: 50%;
  margin-left: -2.0625rem;
}

.erika-mouth {
  top: 7.5rem !important;
  margin-left: -1.5625rem !important;
  width: 3.125rem !important;
  height: 1.25rem !important;
}

.noah-mouth {
  top: 7.5rem !important;
  margin-left: -1.25rem !important;
  width: 2.5rem !important;
  height: 1.25rem !important;
}

.laleh-mouth {
  top: 7.5rem !important;
  left: 5.75rem;
  width: 4rem !important;
  height: 2rem !important;
}

.eye-shadow {
  width: 1.875rem;
  height: 0.9375rem;
  border-radius: 0 0 0.9375rem 0.9375rem;
  background: rgba(149, 35, 0, 0.178);
  position: absolute;
  top: 4.375rem;
}

.eye-shadow#left {
  left: 2.1875rem;
  z-index: 5;
}
.eye-shadow#right {
  right: 2.1875rem;
}
.eyebrow {
  width: 2.5rem;
  height: 0.625rem;
  background: #3f2a14;
  position: absolute;
  top: -2.1875rem;
  left: 50%;
  margin-left: -1.25rem;
  opacity: 1;
}

.erika-brow {
  background: #877a5c !important;
}

.eye {
  width: 1.45rem;
  height: 1.95rem;
  border-radius: 0.625rem;
  background: #453116;
  position: absolute;
  top: -1.125rem;
  left: 50%;
  margin-left: -0.625rem;
}

.erika-eye {
  background: #118863 !important;
}

.noah-eye {
  background: #2071b2 !important;
}

.laleh-eye {
  background: #5d6913 !important;
}

.shadow-wrapper {
  width: 6.125rem;
  height: 16.25rem;
  position: absolute;
  left: -0.5rem;
  bottom: -5.25rem;
  z-index: 4;
}

@keyframes music-move {
  0% {
    -webkit-transform: rotate(0deg) translateZ(0);
    transform: rotate(0deg) translateZ(0);
  }
  33% {
    -webkit-transform: rotate(-5deg) translateZ(0);
    transform: rotate(-5deg) translateZ(0);
  }
  66% {
    -webkit-transform: rotate(5deg) translateZ(0);
    transform: rotate(5deg) translateZ(0);
  }
  100% {
    -webkit-transform: rotate(0deg) translateZ(0);
    transform: rotate(0deg) translateZ(0);
  }
}

@-webkit-keyframes music-move /* Safari and Chrome */ {
  0% {
    -webkit-transform: rotate(0deg) translateZ(0);
    transform: rotate(0deg) translateZ(0);
  }
  33% {
    -webkit-transform: rotate(-5deg) translateZ(0);
    transform: rotate(-5deg) translateZ(0);
  }
  66% {
    -webkit-transform: rotate(5deg) translateZ(0);
    transform: rotate(5deg) translateZ(0);
  }
  100% {
    -webkit-transform: rotate(0deg) translateZ(0);
    transform: rotate(0deg) translateZ(0);
  }
}
