/* #personality.sectionAnimation {
  background: var(--darkest-navy);
} */

#greenSquareTableID {
  opacity: 0;
}

.animationPersonaSection {
  background-color: var(--darkest-navy);
  padding-bottom: 5rem;
  padding-top: 5rem;
}
#personalityContainerID {
  display: none;
  max-width: 100vw;
  margin-top: 1rem;
}

.introPersonalitytext {
  color: var(--lightest-slate);
  font-family: "PressStart" !important;
}

#patienceOneID,
#patienceTwoID,
#patienceThreeID,
#patienceFourID,
#patienceFiveID {
  opacity: 0;
}

#personalityTableID {
  opacity: 0;
  margin: auto;
}

.personalityTable {
  position: relative;
  margin: auto !;
  border-spacing: 0.5rem;
  border-collapse: collapse;
  justify-content: space-between;
  opacity: 0;
}

.personalityTable h4 {
  text-align: left;
  text-indent: auto;
}

.columnHeaderTraits {
  width: 20%;
}

.personalityTraitsRow {
  width: 100%;
}

.personalityBaseRow {
  background-color: transparent !important;
  padding-top: 1.25rem !important;
  border-color: var(--dark-navy) !important;
  border-width: 0.1rem;
  border-style: solid;
  color: black !important;
}

.personalityBaseTraits {
  position: relative;
  padding: 0.3rem;
  margin: auto;
  margin-bottom: 0.15rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  margin-top: 0.15rem;
  font-weight: bold !important;
  font-family: "PressStart" !important;
  color: black;
  opacity: 1;
}

#attributeID {
  background-color: rgb(83, 255, 120);
}

#attributeOneID {
  background-color: rgba(107, 255, 139, 0.7);
}

#attributeTwoID {
  background-color: rgba(107, 255, 139, 0.75);
}

#attributeThreeID {
  background-color: rgba(107, 255, 139, 0.8);
}

#attributeFourID {
  background-color: rgba(107, 255, 139, 0.85);
}

#attributeFiveID {
  background-color: rgba(107, 255, 139, 0.9);
}

#attributeSixID {
  background-color: rgba(107, 255, 139, 0.95);
}

#attributeSevenID {
  background-color: rgba(107, 255, 139, 1);
}

.personalityTraits {
  position: relative;
  padding: 0.3rem;
  margin: auto;
  margin-bottom: 0.15rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  margin-top: 0.15rem;
  color: rgb(83, 255, 120) !important;
  opacity: 1;
  font-family: "PressStart" !important;
  font-size: 0.65rem;
}

.gradeSeven {
  background-color: rgba(107, 255, 139, 1);
  margin-right: 15%;
  opacity: 0;
}

.gradeSix {
  background-color: rgba(107, 255, 139, 0.95);
  opacity: 0;
}

.gradeFive {
  background-color: rgba(107, 255, 139, 0.9);
  opacity: 0;
}

.gradeFour {
  background-color: rgba(107, 255, 139, 0.85);
  opacity: 0;
}

.gradeThree {
  background-color: rgba(107, 255, 139, 0.8);
  opacity: 0;
}

.gradeTwo {
  background-color: rgba(107, 255, 139, 0.75);
  opacity: 0;
}

.gradeOne {
  background-color: rgba(107, 255, 139, 0.7);
  opacity: 0;
}

.personalityTraits .gradeOne .patience {
  opacity: 0;
}

.personalityTextContainer {
  margin: auto;
  opacity: 1;
  display: none;
}

.bookContainer {
  margin-top: -4.75rem;
  margin-bottom: -5rem;
}

.personalityTextContainer .duckText {
  margin: 0;
}

h1 {
  margin-top: 2rem;
}
.personalityFont {
  font-family: "PressStart" !important;
  color: rgb(83, 255, 120) !important;
  white-space: pre-wrap !important;
  font-size: xx-large !important;
}

.personalityBaseTraits h4 {
  font-size: 0.5rem;
}

.personalityTextContainer h4 {
  color: var(--primary-green);
  text-align: left;
  padding: 0.5rem;
}

.personalityTextContainer p {
  text-align: left;
  display: inline-block;
  padding: 0.5rem;
}

.personalityTextContainer .col {
  margin: auto;
}

.personalityCard {
  border: none !important;
  border-radius: 18px !important;
  background-color: var(--secondaryColor-light);
  width: 25rem;
  height: fit-content;
  margin: 2rem;
  cursor: pointer;
  transition: var(--transition);
  box-shadow: var(--btnShadow);
}

.personalityCard:hover {
  background-color: var(--secondaryColor-dark) !important;
}

.personalityCard.selectedPersonalityCard:hover {
  background-color: var(--secondaryColor-dark);
  transition: var(--transition);
}

.personalityCard:active {
  opacity: 0.9;
  scale: 0.95;
}

.personalityCard.selectedPersonalityCard {
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-color: var(--primary-white) !important;
  box-shadow: var(--blackWhiteShadow) !important;
  border: 0.125rem solid black !important;
  border-radius: 18px !important;
  transition: var(--transition);
}

.btnPersonalityCard {
  border: none;
  background-color: transparent !important;
  margin: 0.5rem;
}

.personalityCard h4 {
  margin: auto;
  text-align: center;
  transition: var(--transition);
}

.personalityCloseBtn {
  float: right;
  margin: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0rem;
  padding-top: 0rem;
  border: 0.1rem black solid;
  background-color: var(--secondaryColor);
  font-weight: 800;
  border-radius: 50%;
  display: none;
  box-shadow: var(--menuBtnShadow);
  transition: var(--transition);
  width: 2.25rem;
  height: 2.25rem;
}

.personalityCloseBtn:hover {
  color: var(--slate);
  background-color: var(--secondaryColor-dark) !important;
}

.personalityCard h3 {
  color: var(--primary-white) !important;
  transition: var(--transition);
}

.personalityCard.selectedPersonalityCard h3 {
  color: var(--primaryText) !important;
  transition: var(--transition);
}

.personalityCard h4 {
  color: var(--secondaryColor-darkest) !important;
  transition: var(--transition);
}

.personalityCard.selectedPersonalityCard h4 {
  color: var(--secondaryColor-dark) !important;
  transition: var(--transition);
}

.personalityCard p {
  display: none;
  transition: var(--transition);
}

.card-text {
  display: inline-block;
  transition: var(--transition);
}

.card-body {
  padding: 0.25rem 0.25rem;
}
