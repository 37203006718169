.toolsContainer {
  right: 0;
  position: fixed;
  top: 21.5%;
  border-radius: 18px;
  border: 0.15rem solid black;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  background-color: var(--bg-nav);
  display: flex;
  max-width: 20rem;
  width: fit-content;
  z-index: 999;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.hideToolsMenu {
  transition: var(--transition);
  opacity: 0;
}

.btnShowTools {
  top: 0;
  right: 0;
  position: absolute;
  padding-bottom: 0.05rem;
  border-radius: 18px;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  height: fit-content;
  width: fit-content;
  align-items: center !important;
  justify-items: center !important;
}

.showToolsMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: var(--transition);
  width: 15rem;
  padding: 0.25rem;
}
.extraWidth {
  width: 3rem;
  height: 5rem;
  border-radius: 18px;
  padding: 0.1rem !important;
  padding-top: 0.4rem !important;
  margin: auto !important;
}
.button-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  width: max-content;
  min-width: 90%;
}

.button-col {
  display: inline-block;
  padding: 0.1rem;
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.button-row button {
  margin: auto !important;
  display: inline-flex !important;
}

.toolsContainer > .row {
  width: fit-content !important;
}

.toolsContainer h4 {
  margin-top: -0.85rem;
  margin-left: -0.45rem;
}
