/***************** SKILLS *********************/
#skills {
  margin-top: 3rem;
  padding-top: 3rem;
}

.bg-transparent {
  border: none;
}
.skillContainer {
  margin: auto;
  margin-top: 1rem;
  opacity: 1;
}

#resetSkills {
  border: none;
  background-color: transparent;
  margin: auto;
  scale: 1.5;
  margin-bottom: 1rem;
}

#resetSkills .fntAwesome {
  border-radius: 50%;
  transition: var(--transition);
  margin-top: 1rem !important;
  background-color: var(--secondaryColor);
  border: 0.1rem black solid;
  box-shadow: var(--menuBtnShadow);
}

#resetSkills .fntAwesome:hover {
  background-color: var(--secondaryColor-dark);
}

#resetSkills .fntAwesome:active {
  scale: 0.925;
}

ul.a {
  list-style-type: circle;
  color: var(--secondaryColor-darkest);
}

#duckContainerID {
  margin-top: 2rem;
  opacity: 1;
}

.skillTable {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

.skillTable tbody p {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

button .skillCategory {
  padding: 0;
  margin-left: 5rem;
  margin-right: 5rem;
  background-color: var(--secondaryColor);
}

.skillNavh3 {
  color: var(--primary-white);
  text-align: center !important;
  white-space: nowrap !important;
}

.skillNav {
  background-color: var(--bg-sub-dark);
  border-radius: 18px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: fit-content;
  display: inline-flex !important;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.25rem !important;
  margin-bottom: 1.25rem !important;
  box-shadow: var(--btnShadow);
  border: 0.1rem black solid;
}

.skillCategory {
  padding: 1rem !important;
  width: fit-content;
  height: fit-content;
  opacity: 1;
  margin-bottom: 1.5rem !important;
  margin-top: 0.25rem !important;
  margin-left: 3.75rem !important;
  margin-right: 3.75rem !important;
  border-radius: 18px;
  border: 0.1rem solid black;
  transition: var(--transition);
  box-shadow: var(--btnShadow);
}

.skillCategory:hover {
  transition: var(--transition);
}

.selectedBtn {
  scale: 0.925;
  transition: var(--transition);
}

button .selectedBtn.frontEndTheme {
  background-color: var(--btnHoverColor) !important;
}
button .selectedBtn.backEndTheme {
  background-color: #7a5408 !important;
}

button .selectedBtn.rolesTheme {
  background-color: #3b2801 !important;
}

.skillPointGround {
  padding: 0% !important;
  /* color: var(--secondaryColor-dark); */
  width: min-content;
  margin: auto !important;
  word-break: keep-all !important;
  word-spacing: 0% !important;
  white-space: nowrap !important;
}

.skill {
  width: 85%;
  margin: auto;
  margin-top: 0.5rem;
  padding: 0.3rem !important;
  opacity: 1;
  border-radius: 18px;
  text-align: center !important;
  font-weight: 700 !important;
  box-shadow: var(--btnDesinShadow);
}

.skillP {
  color: var(--primary-white) !important;
}

.frontEndTheme {
  background-color: var(--secondaryColor) !important;
  color: var(--primary-white);
}

.backEndTheme {
  background-color: var(--secondaryColor-dark) !important;
  color: var(--primary-white);
}

.rolesTheme {
  background-color: var(--secondaryColor-shadow) !important;
  color: var(--primary-white);
}

#frontEnd:hover {
  background-color: var(--btnHoverColor) !important;
}

#backEnd:hover {
  background-color: #4e4a11 !important;
}

#roles:hover {
  background-color: #201e01 !important;
}

button .frontEndTheme:hover {
  background-color: var(--btnHoverColor) !important;
}

button .backEndTheme:hover {
  background-color: #7a5408 !important;
}

button .rolesTheme:hover {
  background-color: #3b2801 !important;
}

button .frontEndTheme:active,
button .backEndTheme:active,
button .rolesTheme:active {
  scale: 0.925;
  transition: var(--transition);
  color: var(--primary-white);
}

.duckContainer {
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}
.duckText {
  margin: auto;
  text-align: left;
  margin-top: 16rem;
  display: block;
  width: 90%;
  max-width: 35rem;
  padding: 0;
}

ul.skillBullet {
  list-style-type: disc;
  list-style: var(--secondaryColor-darkest);
}
