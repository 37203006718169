#brandGuyID {
  display: inline-flex;
}

.wrapper-brand {
  display: inline-flex;
}

.background-circle-brand {
  width: 5.75rem;
  height: 5.75rem;
  border-radius: 100%;
  overflow: hidden;
  background: #95b3bf !important;
  background: transparent;
  position: relative;
  border: 0.25rem solid var(--secondaryColor-dark);
  z-index: -1;
  opacity: 1;
}

.triangle-light-brand {
  width: 24rem;
  height: 37.5rem;
  background: #ffffff;
  opacity: 0.2;
  position: absolute;
  right: -50%;
  z-index: 0;
}

.head-brand {
  width: 2.25rem;
  height: 2.5rem;
  border-radius: 3.125rem;
  background: #ffdaa7;
  position: absolute;
  top: 0;
  margin: auto;
  margin-top: 1.7rem;
  margin-left: 1.75rem;
}

.body-brand {
  width: 70%;
  font-family: "Open Sans", sans-serif;
  height: 50%;
  margin: 0 auto;
  background: #423c0a !important;
  border-radius: 6.25rem;
  position: relative;
  top: 2.5rem;
  margin-top: 10%;
  z-index: 1;
  box-shadow: 0.15rem 2rem 2.5rem -1rem #cecbb7,
    inset 0 1rem 1.5rem 2.25rem #373206;
  opacity: 1;
}

.hair-main-brand {
  width: 3.25rem;
  height: 110%;
  background: linear-gradient(#141313 60%, rgb(83, 77, 77) 40%) !important;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  position: relative;
  z-index: 2;
}

.face-brand {
  width: 105%;
  height: 81.5%;
  border-radius: 0.6rem 0.6rem 0 0;
  background: #ffdaa7;
  position: absolute;
  top: 0;
  margin-top: 0.25rem;
  margin-left: -0.05rem;
  z-index: 3;
}

.beard-brand {
  opacity: 1;
  width: 144.5%;
  height: 1.8rem;
  background: black !important;
  border-radius: 0rem 0rem 1rem 1rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  position: relative;
  z-index: 2;
}

.nose-brand {
  background: #df9c3f;
  width: 0.3rem;
  height: 0.7rem;
  border-radius: 0 0 2rem 2.125rem;
  position: absolute;
  left: 50%;
  top: 1.2rem;
  margin-left: -0.2rem;
  opacity: 1;
  z-index: 4;
}

.mouth-brand {
  width: 0.95rem;
  height: 0.45rem;
  border-radius: 0 0 2.0625rem 2.0625rem;
  background: white;
  position: absolute;
  top: 2.25rem;
  left: 29.5%;
}

.eye-brand {
  width: 0.275rem;
  height: 0.425rem;
  border-radius: 0.625rem;
  background: #453116;
  position: absolute;
  top: 0.7rem;
  left: 50%;
  margin-left: -0.5rem;
  opacity: 1;
}

.laleh-kawai-brand {
  width: 0.125rem;
  height: 0.125rem;
  border-radius: 50%;
  margin: auto;
  margin-top: 0.05rem;
  background-color: white;
}

.eye-shadow-brand {
  width: 0.25rem;
  height: 0.9375rem;
  position: absolute;
  top: 0.1rem;
}

.eye-shadow-brand#left {
  left: 0.85rem;
  z-index: 5;
}

.eye-shadow-brand#right {
  right: 0.15rem;
}

.eyebrow-brand {
  width: 0.75rem;
  height: 0.3rem;
  background: #3f2a14;
  position: absolute;
  top: 0.45rem;
  left: 42.5%;
  margin-left: -0.675rem;
  opacity: 1;
  -webkit-backface-visibility: hidden;
}
