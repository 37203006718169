#resumeOverview {
  display: block;
  width: 100%;
  justify-content: center;
  margin-top: 3rem;
  padding-top: 3rem;
}

#resumeContainerID {
  display: block;
}

.resumeTable {
  width: 95%;
  margin: auto;
  border-spacing: 0;
  opacity: 1;
}

.resumeTable h3 {
  margin: auto;
}

.resumeTable p {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 0.9rem;
}

.row > * {
  padding: 0;
}

#resumeOverview h4 {
  color: var(--secondaryColor-darkest);
}

.expOverviewPeriod {
  background-color: var(--primary-white);
  color: var(--primaryText);
  border-radius: 18px;
  margin: auto !important;
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
  border: 0.1rem solid black;
  box-shadow: var(--blackWhiteShadow);
  padding: 0.5rem;
  max-width: 30rem;
}

.resumeExpText {
  text-align: left;
  margin-bottom: 0.1rem !important;
  padding-bottom: 0 !important;
}

.resumeSection {
  color: var(--primary-white);
  /* background: var(--bg-sub-dark); */
  border-radius: 18px;
  margin-bottom: 2vh !important;
  margin: auto !important;
  display: block;
  padding: 0.5rem;
  max-width: 47.5rem;
}

.resumeNavMenu {
  background-color: var(--bg-sub-dark);
  border-radius: 18px;
  padding: 0.25rem;
  margin-bottom: 1rem !important;
  margin: auto;
  margin-top: 2rem;
  justify-content: center;
  box-shadow: var(--btnDesinShadow);
  border: 0.15rem solid black;
}

.resumeNavRow {
  justify-content: center;
  margin-top: 1rem;
}

.resumeNav {
  background-color: var(--secondaryColor);
  color: var(--primary-white) !important;
  border-radius: 18px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  margin-bottom: 1.25rem !important;
  margin: auto;
  margin-left: 1rem;
  margin-right: 1rem;
  box-shadow: var(--btnShadow);
  border: 0.1rem black solid;
}

.resumeNav h3 {
  margin: auto;
  color: var(--primary-white);
  transition: var(--transition);
}

.resumeNav:hover {
  background-color: var(--btnHoverColor);
}

#resumeLangID.resumeNavFocus {
  background: linear-gradient(
    to left,
    var(--btnHoverColor) 50%,
    var(--secondaryColor-darkest)
  );
  background-size: 200%;
  background-position: right bottom;
  border: none !important;
}

#resumeExpID.resumeNavFocus {
  background: linear-gradient(
    to left,
    var(--btnHoverColor) 50%,
    var(--secondaryColor-darkest)
  );
  background-size: 200%;
  background-position: right bottom;
  border: none !important;
}

#resumeEduID.resumeNavFocus {
  background: linear-gradient(
    to left,
    var(--btnHoverColor) 50%,
    var(--secondaryColor-darkest)
  );
  background-size: 200%;
  background-position: right bottom;
  border: none !important;
}

.resumeNav:focus {
  background: linear-gradient(
    to left,
    var(--btnHoverColor) 50%,
    var(--secondaryColor-darkest)
  );
  background-size: 200%;
  background-position: right bottom;
  transition: all 2s ease-out;
  border: none !important;
}

.activeNav {
  background-color: var(--darkest-slate);
  border-color: var(--navy);
  border-style: double;
  border-width: 0.4rem;
}

.resumeSection h3 {
  color: var(--primaryText);
}

.header2UnderLine {
  border-color: var(--secondaryColor-dark);
  border-width: 0.3rem;
  border-style: solid;
  height: 1rem;
  width: 75%;
  margin: auto;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
}

.header3UnderLine {
  background-color: var(--secondaryColor-darkest);
  height: 0.25rem;
  max-width: 40%;
  margin: auto;
  margin-top: 0.5rem;
}

#resumeLangSectionID,
#resumeExpSectionID,
#resumeEduSectionID {
  justify-content: center;
  display: none;
  opacity: 0;
}

.expSubContainer {
  background-color: var(--primary-white);
  border: 0.1rem solid black !important;
  padding-bottom: 1.5rem;
  border: 0.1rem solid;
  justify-items: center;
  border-radius: 18px;
  width: fit-content;
  min-width: 50%;
  margin: auto;
  box-shadow: var(--btnDesinShadow);
}

.expSubContainer > .row {
  width: 80% !important;
  margin: auto !important;
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.expRow {
  padding: 0;
  margin: 0;
  border-bottom: 0.15rem var(--secondaryColor-darkest) solid;
}

.experienceTable td {
  overflow: hidden;
  display: inline-block;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.experienceTable .with-ellipsis td {
  text-overflow: ellipsis;
}
