@media (max-width: 15rem) {
  :root {
    --size-logoDivisor: 7;
  }
}

@media (min-width: 20rem) {
  :root {
    --size-logoDivisor: 2.5;
  }

  .navSubMenu {
    margin-top: -30rem !important;
  }

  #choiceID {
    margin-top: -13.5rem;
  }

  .welcomeText {
    max-width: 100%;
  }
}

@media (min-width: 25rem) {
  :root {
    --size-logoDivisor: 3.25;
  }
}

@media (min-width: 36rem) {
  :root {
    --size-logoDivisor: 2.75;
  }
}

@media (min-width: 48rem) {
  :root {
    --size-logoDivisor: 2.25;
  }

  .welcomeText {
    max-width: 70%;
  }

  #choiceID {
    margin-top: -7.5rem;
  }
}
@media (min-width: 62rem) {
  :root {
    --size-logoDivisor: 2;
  }
}
@media (min-width: 75rem) {
  :root {
    --size-logoDivisor: 1.7;
  }
  #choiceID {
    margin-top: -5rem;
  }
  .welcomeText {
    max-width: 60%;
  }
}

@media (min-width: 85rem) {
  .welcomeText {
    max-width: 50%;
  }
}

#quickFacts {
  background: linear-gradient(
    to bottom right,
    var(--bg) 39.5%,
    #d3bd8152 0%
  ); /* height: 100vh; */
}

#heroBtns {
  opacity: 0;
}

#quickFacts .skillNav {
  background-color: var(--lime-dark-green);
  width: fit-content;
  padding: 0.75rem;
}
#quickFacts .skillNav:hover {
  background-color: var(--lime-darker-green);
}

#quickContact {
  background-color: var(
    --secondaryColor
  ) !important; /* box-shadow: var(--btnDesinShadow); */
}

#quickContact:hover {
  background-color: var(--btnHoverColor) !important;
}

#quickFacts h1,
#quickFacts h3,
#quickFacts #bodyStart,
#quickFacts #headOne {
  opacity: 0;
}

#choiceID {
  opacity: 0;
  display: block;
  z-index: -1;
}

.introText {
  opacity: 1;
  color: #38ff22;
}

.navP {
  color: var(--lightest-slate);
  text-align: left;
  display: inline-flex;
  margin: auto;
  justify-content: center;
}

#arrowDown:hover {
  color: var(--lightest-slate) !important;
}

.navSub {
  margin-left: 0.1%;
  justify-content: center;
}

.navSubIcon {
  color: var(--slate);
  border: none;
  background-color: transparent;
  margin: 0.5rem;
  padding: 0.25rem;
}
.navSubIcon:hover {
  color: var(--primary-white);
}

#Logo {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 50%;
  right: -50%;
  bottom: 0;
  left: -50%;
  margin: auto;
  display: block;
  fill: #007bff;
  fill: url("#MyGradient");
  stroke: #adebbb;
  stroke-miterlimit: 5;
}

#introTextID {
  margin-bottom: 50rem !important;
}

.Animate-Draw {
  fill-opacity: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: DrawLine, FadeStroke, FillIn;
  animation-duration: 2s, 2s, 1s;
  animation-delay: 0s, 1s, 3.5s;
}

#Draw-Text {
  animation-delay: 2s, 3.5s, 3.5s;
  animation-duration: 2s, 1s, 1s;
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
}

@keyframes DrawLine {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes FadeStroke {
  to {
    stroke-opacity: 0;
  }
}

@keyframes FillIn {
  from {
    fill-opacity: 0;
  }
  to {
    fill-opacity: 1;
  }
}

.welcomeText {
  margin: auto !important;
  margin-top: -4.5rem !important;

  text-align: left !important;
  width: fit-content !important;
  /* padding: 0.25rem !important; */
  white-space: none !important;
  /* display: block !important; */
  /* align-content: center !important; */
}

/* Logo */
#logoContainerID {
  margin: auto;
}
.logo {
  transform: scale(calc(2.25 / var(--size-logoDivisor)));
  opacity: 0;
  min-height: 25rem;
  width: min-content;
  margin: auto;
  display: inline-block;
  margin: auto !important;
  margin-bottom: 5rem !important;
  width: 40rem !important;
}

body:not(.iOS) #lines * {
  mix-blend-mode: lighten;
}

#fills * {
  opacity: 0;
  mix-blend-mode: lighten;
}

#line-i-1 {
  background-color: "#00ff00";
  transform-origin: -60px 0px;
}

#line-e {
  transform-origin: 15px 2px;
  transform: scale(4.5);
}

#line-d {
  transform-origin: -40px 46px;
  transform: scale(3.01);
}

#line-p {
  transform-origin: -100px 0px;
  transform: scale(3);
}

#line-s {
  transform-origin: -155px 44px;
  transform: scale(3.2);
}

#line-a-1 {
  transform-origin: -131px 0px;
  transform: scale(4.2);
}

#line-r {
  transform-origin: -140px 1px;
  transform: scale(4.6);
}

#line-a-2 {
  transform-origin: -180px 0px;
  transform: scale(4.2);
}

#line-c {
  transform-origin: -297px 2px;
  transform: scale(3.19);
}

#fill-d {
  transform-origin: -48px 46px;
  transform: scale(3);
}

#fill-p {
  transform-origin: -108px 0px;
  transform: scale(3);
}

#fill-e {
  transform-origin: 10px 2px;
  transform: scale(4.5);
}

#fill-s {
  transform-origin: -162px 44px;
  transform: scale(3.2);
}

#fill-a-1 {
  transform-origin: -136px 0px;
  transform: scale(4.2);
}

#fill-r {
  transform-origin: -144px 1px;
  transform: scale(4.6);
}

#fill-a-2 {
  transform-origin: -185px 0px;
  transform: scale(4.2);
}

#fill-c {
  transform-origin: -305px 2px;
  transform: scale(3.19);
}

/* Fireworks */

.fireworks {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

div c {
  background-color: #0056b3;
}

svg path {
  width: 100%;
  height: 30px;
}

#particule1 {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;

  top: 40%;
  left: 75%;
  background: transparent;
  border-radius: 50%;
}

.ml1 {
  font-weight: 900;
  font-size: 3.5em;
}

.ml1 .letter {
  display: inline-block;
  line-height: 1em;
}

.ml1 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.1em;
  padding-right: 0.05em;
  padding-bottom: 0.15em;
}

.ml1 .line {
  opacity: 0;
  position: absolute;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: #fff;
  transform-origin: 0 0;
}

.ml1 .line1 {
  top: 0;
}
.ml1 .line2 {
  bottom: 0;
}

.playRefreshIntro {
  display: block;
  margin-top: 2rem;
  z-index: 1;
}

.scrollContainer {
  margin-top: -4rem;
}

.scrollIntro {
  /* margin: auto; */
  /* margin-top: 3rem;
  margin-bottom: 3rem; */
  width: 5rem;
  height: 5rem;
  border: 0.5rem solid var(--secondaryColor);
  border-radius: 50%;
  position: relative;
  background-color: var(--primary-white);
  display: inline-flex;
}
.scrollDown {
  margin: auto;
  display: inline-flex;

  width: 1.5rem;
  height: 1.5rem;
  border-bottom: 0.5rem solid var(--secondaryColor-shadow);
  border-left: 0.5rem solid var(--secondaryColor-shadow);
  transform: rotate(-45deg) !important;
}
