.btnRefresh {
  background-color: var(--secondaryColor) !important;
  border: 0.15rem black solid !important;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  opacity: 0;
  pointer-events: none;
  display: none;
  transition: var(--transition) !important;
  box-shadow: var(--btnShadow) !important;
}

.btnPlay {
  background-color: var(--secondaryColor) !important;
  border: 0.15rem black solid;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  transition: var(--transition) !important;
  box-shadow: var(--btnShadow) !important;
}

.fntAwesome {
  width: 1rem;
  height: 1rem;
}

.btnPlay:hover {
  background-color: var(--secondaryColor-dark) !important;
}

.btnRefresh:hover {
  background-color: var(--secondaryColor-dark) !important;
}

.row {
  width: 100%;
}
