:root {
  /*Claymorphism boxShadows*/
  --menuBtnShadow: 0rem 0rem 0.1rem -0.1rem black,
    inset -0.1rem -0.3rem 0.25rem 0.1rem var(--secondaryColor-dark),
    inset 0.05rem 0.025rem 0.1rem 0.1rem var(--primary-white);

  --menuBtnHoverShadow: 0 0.1rem 0.25rem 0 var(--primary-white),
    inset 0 0 1rem 0.5rem var(--navy);

  /*NYA KNAPP SHADOW!!!! */
  --btnShadow: 0.1rem 0.1rem 0.1rem 0.1rem transparent,
    inset -0.1rem -0.15rem 0.2rem 0.1rem var(--secondaryColor-shadow),
    inset 0.4rem 0.425rem 0.2rem -0.3rem var(--primary-white);

  --btnHoverShadow: 0 0.2rem 0.25rem 0 var(--primary-white),
    inset 0 0 1rem 0.5rem var(--secondary-shadow);

  --btnActiveShadow: 0 0.3rem 0.25rem 0 var(--lightest-slate),
    inset 0 0 3rem 0 var(--secondary-shadow);

  --btnDesinShadow: 0 0 0.1rem -0.1rem black,
    inset -0rem -0.1rem 0.2rem 0.1rem var(--secondaryColor-shadow),
    inset 0.2rem 0.4rem 0.5rem -0.3rem var(--primary-white);

  --btnDesinHoverShadow: 0 0 0 0 black, inset 0 0rem 0.25rem 0.25rem var(--navy);

  --btnDesinActiveShadow: 0 -0.1rem 0.1rem 0.2rem black;

  --blackWhiteShadow: 0 0rem 0.1rem 0rem black,
    inset 0rem 0.1rem 0.1rem 0 var(--primary-white);

  --duckMouthUpperShadow: 0 0.1rem 0.25rem 0.1rem black,
    inset 0 -0.2rem 0.75rem 0.1rem var(--navy),
    inset 0 0.4rem 0.5rem -0.2rem var(--primary-white);

  --duckMouthLowerShadow: 0 -0.1rem 0.75rem 0.1rem black,
    inset 0 -0.2rem 0.75rem 0.1rem var(--navy),
    inset 0.2rem -0.5rem 0.25rem -0.1rem var(--primary-white);

  /* Colors */
  --primary-white: rgba(248, 255, 255, 0.993);
  --primary-red: rgba(197, 12, 12, 0.993);

  /*About */
  --primary-lighter-blue: #6ea7df;

  --primary-blue: #1d42bd;
  --primary-darker-blue: #2d6296;
  --primary-darker-green: #2f8f5f;
  --primary-dark-brown: #5f3314;
  --primary-grey: rgb(75, 75, 75);
  --primary-lightDarkGrey: #747070;
  --primary-lightPink: rgb(255, 182, 194);

  /*Duck & Basket*/
  --primary-orange: #f39e00;
  --primary-light-orange: #ffbd42;

  /* SKILLS */
  --primary-lightGrey: #cccccc;

  /*Personality*/
  --primary-green: #0eff06;
  --primary-brown: #96592d;

  /*Book */
  --primary-pink: #e5a7d6;

  --darkest-navy: #050216;
  --dark-navy: #270303;
  --navy: #2f0c0a;
  --light-navy: #401111;
  --lightest-navy: #602828;
  --btnLight-navy: #782121;

  --tint-navy: #7cacfd;
  --navy-shadow: rgba(27, 2, 2, 0.7);
  --darkest-slate: #1d232f;

  --dark-slate: #495670;
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #c9d1f5;
  --whiteBg-hover: #c9d1f5c2;
  --white: #e6f1ff;
  --green: #64ffda;
  --green-tint: rgba(100, 255, 219, 0.404);
  --green-light-tint: rgba(100, 255, 219, 0.486);
  --pink: #f57dff;
  --lime-dark-green: #818823;
  --lime-darker-green: #6b701c;

  --blue: #57cbff;

  /*THESE COLORS ONLY!!!!*/
  /* #ebebc7; */

  --bg-nav: #fbf7ec;

  --bg: #f7f3da1c;
  --bg_2: #e0c98b52;

  --bg-sub-dark: #7757272f;
  --primaryColor: #fcfcf3;
  --primaryText: #3d2e06f8;
  --paragraphText: #252000f8;

  --subHeaderText: #584710;

  --secondaryColor-light: #b8a841;

  --secondaryColor: #918430;
  --secondaryColor-dark: #685a35;
  --secondaryColor-darkest: #4d3a04;
  --secondaryColor-shadow: rgb(51, 40, 2);

  --btnHoverColor: #7a7341;
  --btnActiveColor: #a08e1c;

  --accent: #9ae7a7;

  --font-sans: "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system,
    system-ui, sans-serif;
  --font-mono: "SF Mono", "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  /*CSS TRANSITIONS*/
  --transition: all 0.15s ease-in;
}

/************************* MAIN CSS CLASSES *********************************/

.sectionAnimation {
  background-color: var(--bg_2);
  min-height: 125vh;
  margin: auto;
  display: block;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.sectionAnimation:nth-child(even) {
  background: var(--bg);
}

.primaryContainer {
  /* background-color: var(--primaryColor); */
  /* box-shadow: var(--blackWhiteShadow); */
  max-width: 60vw !important;
  width: fit-content;
  border-radius: 18px;
  display: block;

  margin: auto !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  justify-self: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

button:hover {
  background-color: var(--btnHoverColor);
  transition: var(--transition);
}

button:active {
  transition: var(--transition);
  scale: 0.95;
}

.menuBtn {
  border-radius: 15% 15% 50% 50% !important;
  border: 0.1rem solid black !important;
  transition: var(--transition) !important;
  box-shadow: var(--menuBtnShadow) !important;
}

.menuBtn .fntAwesome {
  background-color: var(--secondaryColor);
  margin: auto;
}
.menuBtn:hover {
  background-color: var(--btnHoverColor);
}

.menuBtn:active {
  color: var(--slate);
  background-color: var(--btnActiveColor) !important;
  scale: 0.95;
}

.btnPlay {
  width: 4rem;
  height: 4rem;
  background-color: transparent;
  border-color: "var(--lightest-slate)" !important;
  border-width: 0.25rem;
  border-style: "dashed" !important;
  border-radius: 50%;
  justify-content: start;
  align-content: space-around;
}

.highLight {
  color: black;
  font-weight: 600;
}

/************************* HTML classes *********************************/

body {
  font-family: "Roboto", "PressStart", sans-serif !important;
  background-color: var(--bg) !important;
}

#root {
  background-color: var(--bg);
}

.bodyDefaultBg {
  background-color: var(--dark-navy);
}

.bodyRedGreen {
  background-color: rgb(9, 9, 34);
}

.bodyYellowBlue {
  background-color: rgb(4, 29, 29);
}

.bodyTotalGray {
  background-color: rgb(15, 15, 15);
}

.colorBlindRedGreen {
  filter: url("./assets/filters.svg#protanopia");
  -webkit-filter: url("./assets/filters.svg#protanopia");
}

.colorBlindYellowBlue {
  filter: url("./assets/filters.svg#tritanopia");
  -webkit-filter: url("./assets/filters.svg#tritanopia");
}

.colorBlindTotal {
  filter: url("./assets/filters.svg#achromatopsia");
  -webkit-filter: url("./assets/filters.svg#achromatopsia");
  transform: translate3d(0, 0, 0);
}

h1 {
  font-family: Fredoka One, sans-serif;
  font-size: 3rem !important;
  font-weight: 1000 !important;
  text-transform: uppercase !important;
  text-align: center !important;
  margin-bottom: 1rem !important;
  color: var(--secondaryColor-dark);
  /* opacity: 0; */
}

h2 {
  font-family: Fredoka One, sans-serif !important;
  font-weight: 850 !important;
  text-transform: uppercase !important;
  font-size: 2.75rem !important;
  color: var(--secondaryColor-dark);
  margin-bottom: 0.25rem !important;
}

h3 {
  font-family: Fredoka One, sans-serif !important;
  font-weight: 700 !important;

  font-size: 1.5rem !important;
  margin: auto !important;
  text-align: center !important;
  word-break: keep-all !important;
  color: var(--subHeaderText);
  /* opacity: 0; */
}

h4 {
  font-family: Fredoka One, sans-serif !important;
  font-weight: 400 !important;
  padding: 0.5rem !important;
  font-size: 1.05rem !important;
}

hr {
  height: 0.25rem !important;
  background-color: var(--secondaryColor-shadow) !important;
  margin-top: 0.1rem !important;
  margin-bottom: 1rem !important;
  opacity: 1 !important;
}

p,
label,
ul > li {
  font-family: Rubik, cursive !important;
  font-weight: 500 !important;

  font-size: 1rem;
  vertical-align: middle !important;
  word-wrap: break-word;
  word-spacing: normal;

  color: var(--paragraphText);
}

.p-italic {
  font-style: italic !important;
}
/* Specialtext - undantag från paragraph*/
.skill {
  font-size: 0.6rem;
}

@media (max-width: 25rem) {
  #resumeOverview,
  #about {
    margin-top: 5rem;
  }
  .skillNav,
  .resumeNav {
    width: 12.5rem;
  }

  /* #expMenu {
    max-width: 10rem;
  } */
  .skillPointGround {
    font-size: 0.95rem;
  }

  .skillTable tbody p {
    font-size: 0.725rem;
  }

  .expOverviewPeriod p {
    font-size: 0.7rem;
    font-weight: 740;
  }

  .resumeNavMenu {
    width: 8rem;
    display: flex;
  }

  .duckText {
    width: 90%;
  }
  .personalityTraits,
  .personalityBaseTraits {
    font-size: 0.65rem !important;
  }
}

@media (min-width: 25.1rem) {
  #resumeOverview,
  #about {
    margin-top: 5rem;
  }
  .skillNav,
  .resumeNav {
    width: 12.75rem;
  }

  /* #expMenu {
    max-width: 10rem;
  } */

  .resumeNavMenu {
    width: 8rem;
    display: flex;
  }
  .skillTable tbody p {
    font-size: 0.9rem;
  }

  .duckText {
    width: 75%;
  }

  .personalityTraits,
  .personalityBaseTraits {
    font-size: 0.65rem !important;
  }
}

@media (min-width: 30rem) {
  h3 {
    font-size: 2rem !important;
  }
  .skillTable tbody p {
    font-size: 0.8375rem;
  }
  .duckText {
    max-width: 30rem;
  }

  .personalityTextContainer {
    width: 75% !important;
  }
}

@media (min-width: 47.5rem) {
  .skillNav,
  .resumeNav {
    width: 15rem;
  }

  #skillsNavMenu,
  #resumeNavMenuID {
    width: 45rem;
    display: inline-flex;
  }

  #skillsNavRow,
  #resumeNavRowID {
    display: inline-flex;
    width: min-content;
  }
}

@media (min-width: 75rem) {
  .skillNav,
  .resumeNav {
    width: 17.5rem;
  }

  .resumeNavMenu {
    width: 50%;
    display: inline-flex;
  }

  .resumeNavRow {
    display: inline-flex;
    width: min-content;
  }
  /* #expMenu {
    max-width: 80rem;
  } */
}

@media (min-width: 60rem) {
  .skill {
    font-size: 1rem !important;
  }

  p,
  ul > li,
  .skill {
    font-size: 0.95rem;
  }

  .periodColContainer {
    margin: auto;
  }
}

@media (min-width: 120rem) {
  h1 {
    font-size: 5.5rem !important;
  }

  h2 {
    font-size: 4.75rem !important;
  }

  h3 {
    font-size: 2.5rem !important;
  }

  .navLinkH4 {
    font-size: 1.4rem !important;
  }

  h4 {
    font-size: 1.3rem !important;
  }

  p {
    font-size: 1.25rem !important;
  }

  .carousel-root {
    max-width: 50vw;
  }
  .testimonials-content {
    margin: auto !;
    display: inline-block;
    transform: scale(1.5) !important;
    width: 35vw;
    margin-top: 5rem !important ;
    justify-content: center;
  }

  .skillCategory {
    font-size: 3rem !important;
  }

  p,
  ul > li,
  .skill,
  .personalityBaseTraits,
  .personalityTraits {
    font-size: 1.25rem !important;
  }

  #resumeOverview,
  #about {
    margin-top: 0;
  }
}

/************************* NAVBAR *********************************/
/* Handle */
::-webkit-scrollbar:vertical {
  width: 0.9rem;
}

::-webkit-scrollbar:horizontal {
  height: 0.65rem !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: var(--blackWhiteShadow);
  background-color: var(--primary-white);
  border-radius: 10px;
  border: 0.15rem black solid;
}

/* Handle */
::-webkit-scrollbar-thumb:vertical {
  background: var(--secondaryColor-dark);
  border-radius: 18px;
  height: 0.25rem !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:vertical:hover {
  background: var(--secondaryColor);
  height: 0.25rem !important;
}

::-webkit-scrollbar-thumb:vertical:active {
  background: var(--secondaryColor-darkest);
  height: 0.25rem !important;
}

.fntAwesome {
  color: var(--primary-white);
  padding-top: 0.5rem;
  height: 1.3rem !important;
  width: 1.6rem !important;
}

.navLinkH4 {
  font-size: 1.05rem;
}

.navbar-toggler {
  border-color: none !important;
  border: none !important;
  right: 0 !important;
  position: absolute !important;
  top: 0 !important;
  margin: 0.15rem !important;
  height: 2.5rem !important;
}

#navLinkHomeID:focus {
  border-bottom: 0.15rem solid var(--secondaryColor-dark) !important;
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkHomeID.navFocus {
  border-bottom: 0.15rem solid var(--secondaryColor-dark) !important;
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkAboutID:focus {
  border-bottom: 0.15rem solid var(--secondaryColor-dark) !important;
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkAboutID.navFocus {
  border-bottom: 0.15rem solid var(--secondaryColor-dark) !important;
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkSkillsID:focus {
  border-bottom: 0.15rem solid var(--secondaryColor-dark) !important;
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkSkillsID.navFocus {
  border-bottom: 0.15rem solid var(--secondaryColor-dark) !important;
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkPersonalityID:focus {
  border-bottom: 0.15rem solid var(--secondaryColor-dark) !important;
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkPersonalityID.navFocus {
  border-bottom: 0.15rem solid var(--secondaryColor-dark) !important;
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkResumeOverviewID:focus {
  border-bottom: 0.15rem solid var(--secondaryColor-dark) !important;
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkResumeOverviewID.navFocus {
  border-bottom: 0.15rem solid var(--secondaryColor-dark) !important;
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkExperiencesID:focus {
  border-bottom: 0.15rem solid var(--secondaryColor-dark) !important;
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkExperiencesID.navFocus {
  border-bottom: 0.15rem solid var(--secondaryColor-dark) !important;
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkContactID:focus {
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkContactID.navFocus {
  color: var(--secondaryColor-darkest) !important;
  opacity: 1 !important;
}

#navLinkSkillsID.navFocus > h4,
#navLinkResumeOverviewID.navFocus > h4,
#navLinkExperiencesID.navFocus > h4,
#navLinkAboutID.navFocus > h4,
#navLinkPersonalityID.navFocus > h4,
#navLinkContactID.navFocus > h4 {
  color: var(--darkest-navy) !important;
}

#navLinkSkillsID.focus > h4,
#navLinkResumeOverviewID.focus > h4,
#navLinkExperiencesID.focus > h4,
#navLinkAboutID.focus > h4,
#navLinkPersonalityID.focus > h4,
#navLinkContactID.focus > h4 {
  color: var(--darkest-navy) !important;
}

.navbar {
  position: sticky !important;
  position: -webkit-sticky !important;
  z-index: 2 !important;
  transition: transform 0.5s ease-in-out !important;
  min-height: 5rem !important;
  overflow: auto !important;
  top: 0 !important;
}

.customNav {
  background-color: var(--bg-nav);
  position: sticky !important;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow-y: auto !important;
  border-bottom: 0.1rem solid black;
  padding-bottom: 0rem !important;
}

/* .customNavBrand {
  display: flex;
  top: -0.5rem;
  position: absolute;
  margin: 1rem;
  padding: 0.5rem;
  margin-right: 0.5rem;
} */

.customBrand {
  width: fit-content !important;
}

.customBrand > .nav-link {
  padding-bottom: 0rem !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.navTest {
  width: 5rem;
  height: 5rem;
  background-color: black;
}

#navbarTogglerID {
  width: 3rem;

  background-color: var(--secondaryColor);
  border: 0.1rem black solid !important;
}
#navbarTogglerID:hover {
  background-color: var(--btnHoverColor);
}

.linksContainer {
  display: flex;
  height: min-content;
  width: min-content;
}

.linksContainer h4 {
  margin: 0;
  padding: 0;
}

.navSubIcon {
  background-color: var(--secondaryColor) !important;
  width: 2.5rem;
  display: flex;
  border-radius: 50%;
  transition: var(--transition);
  box-shadow: var(--btnShadow);
  padding-top: 0.3rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  margin-left: 0.15rem !important;
}

.navSubIcon:hover {
  background-color: var(--secondaryColor-dark) !important;
  transition: var(--transition) !important;
}

.navSubIcon:active {
  background-color: var(--btnActiveColor) !important;
  scale: 0.95 !important;
  transition: var(--transition) !important;
}

.toolBtnActive {
  background-color: var(--btnActiveColor) !important;
  scale: 0.95 !important;
}

#navIconID {
  color: var(--primary-white) !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.green {
  color: #0eff06;
}
nav a.nav-link {
  cursor: pointer;
}

.hideNavbar {
  opacity: 0;
}

#navItemContact {
  background-color: var(--lime-dark-green);
  width: fit-content;
  border-radius: 18px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: fit-content;
  display: inline-flex !important;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.25rem !important;
  margin-bottom: 1.25rem !important;
  border: 0.1rem black solid;
}
#navItemContact:hover {
  background-color: var(--lime-darker-green);
}
#navItemContactH4 {
  color: var(--primary-white) !important;
  margin-top: -0.55rem;
  padding: 0.25rem;
}

a.nav-link h4 {
  color: var(--paragraphText) !important;
  text-align: left !important;
  padding-bottom: 0.1rem !important;
}

a.nav-link h4:hover {
  color: var(--secondaryColor-dark) !important;
}
.nav-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: var(--secondaryColor-darkest);
}

.underline-animation {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0.2rem;
  background-color: var(--secondaryColor-dark);
  transition: width 0.3s ease-in-out;
}

.nav-link:hover .underline-animation {
  width: 100%;
}

nav ul li {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.navbar-hidden {
  transform: translateY(-100%);
  transition: transform 1s ease-in-out;
}

@media (max-width: 767.98px) {
  .align-self {
    align-self: baseline !important;
  }
}

@media (min-width: 768px) {
  .align-self {
    align-self: center !important;
  }
}

.align-self {
  margin: 0.25rem;
}

.align-self > h3 {
  margin-bottom: 0.1rem;
}

.navbar-nav > li > a {
  border-bottom-color: transparent;
  padding-bottom: 0.1rem;
  margin-bottom: 0.1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.btnLabelNav {
  margin: auto !important;
  /* margin-left: -0.35rem !important;
  margin-right: 0.5rem !important; */
  display: flex;
  font-weight: bold !important;
  color: var(--darkest-slate);
  /* margin-left: 0.25rem; */
}
