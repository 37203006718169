:root {
  --size-edipGuyDivisor: 1.5;
}

@media (max-width: 19.9rem) {
  :root {
    --size-edipGuyDivisor: 6;
  }
}

@media (min-width: 20rem) {
  :root {
    --size-edipGuyDivisor: 4;
  }
}
@media (min-width: 25rem) {
  :root {
    --size-edipGuyDivisor: 3.75;
  }
}
@media (min-width: 40rem) {
  :root {
    --size-edipGuyDivisor: 3.5;
  }
}

@media (min-width: 50rem) {
  :root {
    --size-edipGuyDivisor: 3;
  }
}

@media (min-width: 60rem) {
  :root {
    --size-edipGuyDivisor: 2.5;
  }
}

@media (min-width: 120rem) {
  :root {
    --size-edipGuyDivisor: 2;
  }
}

#headOne {
  left: 15.5rem;
}

#tableStartGuyID {
  transform: scale(calc(2.1 / var(--size-edipGuyDivisor)));
  width: 100%;
  transform-origin: top left;
  /* display: flex; */
  /* margin: 0;
  padding: 0; */
}

.wrapper {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  /* border: 1rem solid var(--primary-white); */
  border-radius: 50%;
  padding: 1rem;
  width: 100%;
  height: fit-content;
}

.edipCircleBackground-circle {
  width: 29.5rem;
  height: 29.5rem;
  border-radius: 100%;
  overflow: hidden;
  background: #95b3bf !important;
  background: transparent;
  position: relative;
  border: 1.25rem solid var(--secondaryColor);
  z-index: -1;
  opacity: 0;
}

.body {
  width: 70%;
  font-family: "Open Sans", sans-serif;
  height: 50%;
  margin: 0 auto;
  background: #423c0a !important;
  border-radius: 6.25rem;
  position: relative;
  top: 13.5rem;
  margin-top: 10%;
  z-index: 1;
  box-shadow: 0.15rem 2rem 2.5rem -1rem #cecbb7,
    inset 0 1rem 1.5rem 2.25rem #373206;
  opacity: 0;
}

.cupSteamContainerTable {
  margin-left: 15rem;
  margin-top: -11.25rem;
}

.steam-container {
  width: 3.75rem;
  height: 3.125rem;
  opacity: 0;
}

.coffee-cup {
  width: 5rem;
}

.coffee-cup-container {
  opacity: 0;
}
.squiggle-container {
  width: 0.625rem;
  height: 1.875rem;
  display: inline-block;
}

.squiggle-container-1 {
  transform: translateY(0.625rem);
  animation: move-and-fade 4s infinite ease-in-out;
  animation-fill-mode: forwards;
}

.squiggle-container-2 {
  transform: translateY(0rem);
  animation: move-and-fade 4s infinite ease-in-out;
  animation-fill-mode: forwards;
}

.squiggle-container-3 {
  transform: translateY(0.9375rem);
  animation: move-and-fade 4s infinite ease-in-out;
  animation-fill-mode: forwards;
}

.squiggle {
  stroke-dasharray: 100;
}

.steamPath {
  fill: none;
  stroke-width: 10;
  stroke: #fff;
}

.cupA {
  background-color: black;
  width: 3.5rem;
  border-radius: 10px;
  height: 3.2rem;
  border: none;
  margin-left: -0.5rem;
  margin-top: 0.33rem;
}

.right-hand {
  background-color: #ffe4be;
  height: 3.125rem;
  width: 3.125rem;
  margin-top: 3.6rem;
  margin-left: 50%;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 0.25rem 0 #f4c37e, inset 0 0 1.5rem 0.25rem #9c7742;
  opacity: 0;
}

.left-hand {
  border-radius: 50%;
  background-color: #ffe4be;
  height: 3.125rem;
  width: 3.125rem;
  margin-left: -3.5rem;
  margin-top: 4rem;
  position: absolute;
  z-index: 1;
  box-shadow: 0 0 0.25rem 0 #f4c37e, inset 0 0 1.5rem 0.25rem #9c7742;
  opacity: 0;
}

.mouth {
  width: 4.125rem;
  height: 2.0625rem;
  border-radius: 0 0 2.0625rem 2.0625rem;
  background: white;
  position: absolute;
  top: 9.375rem;
  left: 50%;
  margin-left: -2.0625rem;
}

.eye-shadow {
  width: 1.875rem;
  height: 0.9375rem;
  border-radius: 0 0 0.9375rem 0.9375rem;
  background: rgba(149, 36, 0, 0.1);
  position: absolute;
  top: 4.375rem;
}

.eye-shadow#left {
  left: 2.1875rem;
  z-index: 5;
}

.eye-shadow#right {
  right: 2.1875rem;
}

.eyebrow {
  width: 2.5rem;
  height: 0.625rem;
  background: #3f2a14;
  position: absolute;
  top: -2.1875rem;
  left: 50%;
  margin-left: -1.25rem;
  opacity: 1;
  -webkit-backface-visibility: hidden;
}

.shadow-wrapper {
  width: 6.125rem;
  height: 16.25rem;
  position: absolute;
  left: -0.5rem;
  bottom: -5.25rem;
  z-index: 4;
  overflow: hidden;
}

.triangle-light {
  width: 24rem;
  height: 37.5rem;
  background: #ffffff;
  opacity: 0.2;
  position: absolute;
  right: -50%;
  z-index: 0;
}
