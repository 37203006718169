/* bungee-regular - latin */
@font-face {
  font-family: "Bungee";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./assets/fonts/headers/bungee-v11-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Bungee Bold"),
    url("./assets/fonts/headers/bungee-v11-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/headers/bungee-v11-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/headers/bungee-v11-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/headers/bungee-v11-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/headers/bungee-v11-latin-regular.svg#Bungee")
      format("svg"); /* Legacy iOS */
}

/* fredoka-one-regular - latin */
@font-face {
  font-family: "Fredoka One";
  font-style: normal;
  font-weight: 900;
  font-display: swap;

  src: url("./assets/fonts/headers/fredoka-one-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Fredoka One Bold"),
    url("./assets/fonts/headers/fredoka-one-v13-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./assets/fonts/headers/fredoka-one-v13-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/headers/fredoka-one-v13-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/headers/fredoka-one-v13-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/headers/fredoka-one-v13-latin-regular.svg#FredokaOne")
      format("svg"); /* Legacy iOS */
}
/* fredoka-one-regular - latin */

/* rubik-regular - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/normal/rubik-v23-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Rubik"),
    url("./assets/fonts/normal/rubik-v23-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/normal/rubik-v23-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/normal/rubik-v23-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/normal/rubik-v23-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/normal/rubik-v23-latin-regular.svg#Rubik")
      format("svg"); /* Legacy iOS */
}

/* press-start-2p-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "PressStart";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/normal/press-start-2p-v14-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/normal/press-start-2p-v14-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./assets/fonts/normal/press-start-2p-v14-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/normal/press-start-2p-v14-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/normal/press-start-2p-v14-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/normal/press-start-2p-v14-latin-regular.svg#PressStart2P")
      format("svg"); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: Rubik;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

heading {
  font-family: Fredoka One, PressStart, sans-serif !important;
}
