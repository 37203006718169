:root {
  --size-duckDivisor: 1.5;
}
@media (max-width: 19.9rem) {
  :root {
    --size-duckDivisor: 6;
  }
}

@media (min-width: 20rem) {
  :root {
    --size-duckDivisor: 4;
  }
}
@media (min-width: 25rem) {
  :root {
    --size-duckDivisor: 3.75;
  }
}

#duckID {
  transform: scale(calc(2 / var(--size-duckDivisor)));
  position: absolute;
  margin-top: -5rem;
}

.duckWrapper {
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

.duckEdipCircleBackground-circle {
  width: 26.25rem;
  height: 26.25rem;
  border-radius: 100%;
  overflow: hidden;
  background: #95b3bf !important;
  background: transparent;
  position: relative;
  border: 1rem solid var(--secondaryColor-dark);
  z-index: -1;
}

.duckHead {
  width: 12.25rem;
  height: 16.25rem;
  border-radius: 3.125rem;
  background: #eeebe6;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13.125rem;
  margin-left: -6.125rem;
  z-index: 3;
}

.duckFace {
  width: 9.25rem;
  height: 12.5625rem;
  border-radius: 3rem 3rem 0 0;
  border-bottom: none !important;
  background: #eeebe6;
  position: absolute;
  top: 2.5rem;
  left: 33%;
  z-index: 4;
  box-shadow: 0.5rem -0.35rem 0.5rem -0.15rem var(--slate),
    inset -0rem 0.1rem 0.2rem -0.1rem var(--light-navy),
    inset 0.2rem 0.4rem 0.5rem -0.3rem var(--primary-white);
}

.duckBody {
  width: 72%;
  font-family: "Open Sans", sans-serif;
  height: 37.5rem;
  margin: 0 auto;
  background: #eeebe6;
  border-radius: 6.25rem;
  position: relative;
  left: 6%;
  top: 12.5rem;
  margin-bottom: 5%;
  z-index: 0;
  box-shadow: var(--btnShadow);
}

.duckEye {
  width: 1.25rem;
  height: 1.75rem;
  border-radius: 0.625rem;
  background: #453116;
  position: absolute;
  top: -1.125rem;
  left: 50%;
  margin-left: -0.625rem;
  box-shadow: var(--btnDesinShadow);
}

.duckEye-shadow {
  width: 1.875rem;
  height: 0.9375rem;
  border-radius: 0 0 0.9375rem 0.9375rem;
  background: rgba(149, 36, 0, 0.1);
  position: absolute;
  top: 4.375rem;
}

.duckEye-shadow#duckLeft {
  left: 3rem;
  z-index: 5;
}

.duckEye-shadow#duckRight {
  right: 7.5rem;
}

.duckEyebrow {
  width: 2.75rem;
  height: 1rem;
  background: #3f2a14;
  position: absolute;
  top: -2.25rem;
  left: 50%;
  margin-left: -1.25rem;
  opacity: 1;
  -webkit-backface-visibility: hidden;
  z-index: 100 !important;
}

.duckMouthUpper {
  width: 9rem;
  height: 2rem;
  border-radius: 0 2.0625rem 0 2.0625rem;
  background: var(--primary-orange);
  position: absolute;
  top: 7rem;
  right: 75%;
  border: 0.1rem solid black;
  /* box-shadow: var(--duckMouthUpperShadow); */
}

.duckMouthUnder {
  width: 9rem;
  height: 2rem;
  border-radius: 2.0625rem 0 2.0625rem 0;
  background: var(--primary-orange);
  position: absolute;
  top: 9.25rem;
  right: 75%;
  border: 0.1rem solid black;

  /* box-shadow: var(--duckMouthLowerShadow); */
}

.shadow-wrapper {
  width: 6.125rem;
  height: 16.25rem;
  position: absolute;
  left: -0.5rem;
  bottom: -5.25rem;
  z-index: 4;
  overflow: hidden;
}
