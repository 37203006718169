.greenSquare{
    width:1rem;
    height: 1rem;
    background-color:rgb(240, 122, 185);
  }

  .darkGreen{
    background-color: rgb(240, 122, 185);
    

  }

  .greenTopSquareInner{
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 20%;

    width:1rem;
    height: 1rem;
    background-color: chartreuse;
  }

  .emptySquare{
    width:0.25rem;
    height: .25rem;
    background-color: transparent;

  }

  .greenSquareTable{
    opacity: 1;
    margin-bottom: 1rem;
    border-collapse: separate;
    

  }

  