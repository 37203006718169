@media (max-width: 39.9rem) {
  :root {
    --size-familyDivisor: 2.75;
  }

  .testimonials-content {
    max-width: 95vw !important;
  }
  .carousel-root {
    max-width: 100% !important;
  }
}

@media (min-width: 40rem) {
  .testimonials-content {
    max-width: 75vw !important;
  }
  .carousel-root {
    max-width: 95% !important;
  }
}

@media (min-width: 60rem) {
  :root {
    --size-familyDivisor: 2.7;
  }

  .testimonials-content {
    max-width: 50vw !important;
  }
}

@media (min-width: 75rem) {
  .testimonials-content {
    max-width: 60vw !important;
  }
  .carousel-root {
    max-width: 75% !important;
  }
}

#about {
  margin-top: 3rem;
  padding-top: 3rem;
}

.testimonials {
  background-size: cover;
  text-align: center;
  /* padding-bottom: 4rem; */
  margin: auto;
}

.testimonials-content {
  text-align: center;
  height: max-content;
  margin: auto;
  justify-content: center;
  margin-top: -2rem;
  background: none;
  transform: (0.95);
}

.testimonials h3 {
  color: var(--primary-white);
  /* margin-top: 2.15rem !important; */
}

.testimonials p {
  color: var(--primary-white);
  /* font-size: large !important; */
  margin: auto !important;
  padding-left: 1rem;
  padding-right: 1rem;
}

.carousel .slide img {
  width: 10rem !important;
  margin-top: 2rem;
}

.carousel .slide {
  background: transparent !important;
  height: 100% !important;
  padding-top: 2rem;
  margin-bottom: 7rem !important;
}
.carousel .control-dots {
  background: var(--secondaryColor) !important;
  height: 2.25rem !important;

  justify-content: center !important;
  align-content: center !important;
  border-radius: 5px !important;
  box-shadow: var(--btnDesinShadow);
}

.carousel {
  margin-bottom: 6rem !important;
}

.control-dots {
  margin-bottom: 3.75rem !important;
}

.carousel-root {
  margin: auto !important;
  margin-top: 2.5rem !important;
  /* background-color: var(--primaryColor) !important; */
  /* box-shadow: var(--blackWhiteShadow) !important; */
  /* width: fit-content !important; */
  min-width: 50% !important;
  max-height: 29rem !important;
  padding: 0.1rem !important;
  border-radius: 18px !important;
  display: inline-block;
}
.carousel.carousel-slider .control-arrow {
  bottom: 0 !important;
  top: -15% !important;
  margin: 0 !important;
  padding: 0 !important;
}
.carousel .control-prev.control-arrow:before {
  border-right: 0.75rem solid var(--secondaryColor) !important;

  /* margin-left: 0.25rem !important; */
}

.carousel .control-next.control-arrow:before {
  border-left: 0.75rem solid var(--secondaryColor) !important;
  /* margin-right: 0.75rem !important; */
}

.control-next:hover,
.control-prev:hover {
  background-color: transparent !important;
  color: var(--secondaryColor-dark) !important;
}

.control-next,
.control-prev {
  scale: 1.5 !important;
  color: var(--secondaryColor) !important;
  opacity: 1 !important;
}

.carousel .control-dots .dot {
  opacity: 0.9 !important;
  margin-top: 0.5rem !important;
  color: var(--secondaryColor) !important;
}

.dot.selected {
  background-color: var(--secondaryColor-darkest) !important;
}

.containerSlide {
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  right: 0;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* max-width: 40rem; */
  width: 100%;
  margin-top: 2rem !important;
  margin: auto;
  border-radius: 18px !important;
  box-shadow: -0.1rem 0.25rem 0.25rem -0.1rem black,
    inset -0.3rem -0.2rem 0.5rem 0.25rem var(--darkest-slate),
    inset 0.2rem 0.4rem 0.5rem -0.2rem var(--primary-white);
}

.containerSlide h2 {
  margin-bottom: 0;
  margin-top: 1rem;
}

.basketContainer {
  background: var(--primary-blue);
  height: 25rem;
  margin-top: 2rem;
  z-index: -2;
}

.basketBg {
  display: inline-block;
  background-color: var(--primary-grey);
  justify-content: center;
  width: 85%;
  height: 40%;
  margin-left: -0.25rem;
  margin-top: 12.75rem;
  margin-bottom: 0.75rem;
  border-radius: 18px;

  border-radius: 5px;
}

.starWarsBg {
  background-color: black;
  overflow-x: hidden;
  height: 26.5rem;
}

.stars {
  margin: auto;
  z-index: -30 !important;
  margin-top: 10rem;
  height: 1px;
  width: 1px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 13rem -7rem 0px 0px #fff, -9rem 1rem 2px 2px #fff,
    -10rem 7rem 1px 0px #fff, 30rem 8rem 0px 1px #fff, 27rem 11rem 1px 1px #fff,
    -30rem -6rem 0px 1px #fff, 5rem 15rem 1px 2px #fff, 28rem 9rem 3px 2px #fff,
    40rem 2rem 2px 2px #fff, -11rem 7rem 3px 3px #fff, -31rem 4rem 2px 3px #fff,
    -31rem -13rem 3px 1px #fff, -23rem -1rem 2px 3px #fff,
    24rem 8rem 2px 2px #fff, -44rem -14rem 2px 0px #fff, 34rem 1rem 2px 0px #fff,
    48rem 8rem 3px 3px #fff, -32rem -6rem 0px 0px #fff, 27rem -2rem 0px 2px #fff,
    -27rem -13rem 2px 2px #fff, -22rem 5rem 0px 2px #fff,
    -5rem 14rem 2px 2px #fff, -37rem -12rem 2px 3px #fff,
    -19rem -1rem 1px 0px #fff, -50rem 3rem 0px 0px #fff,
    17rem -9rem 0px 1px #fff, 2rem -15rem 3px 1px #fff, 25rem -5rem 2px 3px #fff,
    7rem 11rem 2px 3px #fff, -28rem -11rem 1px 0px #fff, 3rem 14rem 2px 2px #fff,
    31rem 15rem 3px 3px #fff, 14rem -14rem 1px 1px #fff,
    -35rem -4rem 3px 3px #fff, -48rem -1rem 2px 1px #fff,
    -3rem 3rem 3px 0px #fff, -11rem 6rem 0px 3px #fff, -47rem 7rem 3px 3px #fff,
    -30rem 9rem 1px 3px #fff, -11rem -10rem 3px 2px #fff, 5rem 7rem 1px 3px #fff,
    46rem -11rem 0px 0px #fff, 8rem 12rem 3px 0px #fff, 35rem 10rem 2px 0px #fff,
    38rem -7rem 0px 0px #fff, 47rem -6rem 2px 3px #fff, -3rem 8rem 0px 0px #fff,
    41rem -5rem 0px 0px #fff, 30rem 2rem 2px 0px #fff, 34rem 13rem 2px 2px #fff,
    -40rem 3rem 0px 2px #fff, -50rem -6rem 1px 0px #fff, 6rem -4rem 2px 1px #fff,
    27rem 1rem 1px 0px #fff, -6rem -13rem 3px 1px #fff, 37rem 0rem 1px 2px #fff,
    -20rem 3rem 3px 0px #fff, -12rem 13rem 0px 2px #fff,
    40rem -2rem 1px 2px #fff, -33rem -3rem 0px 1px #fff,
    19rem 14rem 2px 2px #fff, -4rem 5rem 1px 1px #fff, -16rem -3rem 2px 3px #fff,
    -22rem 7rem 2px 1px #fff, -19rem -11rem 3px 1px #fff,
    29rem -14rem 2px 0px #fff, -4rem 3rem 3px 3px #fff,
    -43rem -9rem 2px 2px #fff, -19rem 9rem 0px 1px #fff,
    17rem -15rem 3px 3px #fff, 43rem -15rem 2px 0px #fff,
    17rem -8rem 2px 2px #fff, 10rem 2rem 2px 0px #fff,
    -32rem -10rem 1px 0px #fff, -11rem -9rem 3px 1px #fff,
    46rem -10rem 1px 0px #fff, 46rem 4rem 3px 3px #fff, 36rem -7rem 1px 1px #fff,
    -45rem 14rem 2px 0px #fff, -43rem 12rem 1px 0px #fff,
    -24rem 7rem 1px 2px #fff, 6rem -1rem 0px 2px #fff, -40rem -4rem 0px 0px #fff,
    -46rem -15rem 1px 0px #fff, -28rem 11rem 3px 0px #fff,
    -24rem 5rem 1px 2px #fff, 9rem 5rem 2px 3px #fff, 42rem -4rem 2px 1px #fff,
    44rem -13rem 0px 3px #fff, 20rem -6rem 3px 3px #fff,
    -7rem -3rem 1px 2px #fff, -1rem 5rem 1px 2px #fff, 44rem 6rem 1px 1px #fff,
    -38rem -14rem 2px 0px #fff, 31rem -2rem 1px 0px #fff,
    -18rem -4rem 1px 3px #fff, 50rem -6rem 3px 2px #fff,
    34rem -7rem 1px 0px #fff, 15rem -11rem 3px 2px #fff,
    -22rem -4rem 2px 0px #fff;
}

.flag-holder {
  width: 25%;
  height: 25%;
}

.flagContainer {
  display: inline-block;
  margin-left: -4rem;
}

.cityHall {
  transform: scale(1.5);
  margin: auto;
  display: block;
  justify-content: center;
  margin-top: 2rem;
}

.weightsText {
  margin-top: 1.25rem !important;
  padding-top: 0 !important;
  line-height: 4rem !important;
}

.customArrowBtn {
  padding: 0.5rem !important;
  width: 5rem;
  height: 3rem;
  border-radius: 18px;
  border: 0.1rem solid black;
  transition: var(--transition);
  box-shadow: var(--menuBtnShadow);
  background-color: var(--secondaryColor);
  margin-bottom: -1rem !important;
}

.customLeftArrowBtn {
  top: 0;
  left: 0;
  position: absolute;
  margin-bottom: 0.5rem !important;
  margin-left: 1rem !important;
  margin-top: 0.5rem !important;
  z-index: 1 !important;
}

.customRightArrowBtn {
  top: 0;
  right: 0;
  position: absolute;
  margin-bottom: 0.5rem !important;
  margin-right: 1rem !important;
  margin-top: 0.5rem;
}

.customArrowBtn:hover {
  background-color: var(--btnHoverColor);
  transition: var(--transition);
}

.customArrowBtn:active {
  background-color: var(--btnHoverColor);
  scale: 0.95;
  transition: var(--transition);
}

.leftBtn {
  transform: rotate(180deg);
  width: 2rem;
  height: 1.5rem;
  padding: 0;
  border-style: solid;
  color: var(--primary-white);
  border: none;
  margin: auto;
  margin-left: -0.25rem !important;
  margin-top: 0.15rem;
}

.rightBtn {
  width: 2rem;
  height: 1.5rem;
  padding: 0;
  border-style: solid;
  color: var(--primary-white);
  border: none;
  margin: auto;
  margin-top: 0.15rem;
}

.clayHouseContainer {
  border-radius: 18px;
  margin: auto;
  padding: 0;
  top: 5.75rem;
  display: inline-flex;
  position: absolute;
}
.clayHouse {
  border-radius: 18px;
  scale: 3.5;
  bottom: 0;
  right: 50;
  left: 50;
  top: 1rem;

  overflow: hidden;
}
